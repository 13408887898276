import request from '@/utils/request'
import moment from 'moment'
const elevatorApi =''
// 电梯模块--设备页

/**************************************************************** */
// 方法工具（注：没写在utils是只作用于梯控模块，故梯控模块所需要的公共的方法）
/**************************************************************** */
export function isEmptrValue(value){
    if(value==null){
        return true
    }
    if(value == undefined){
        return true
    }
    const type = Object.prototype.toString.call(value).slice(8,-1)
    switch(type){
        case 'String':
            return value === '';
        case 'Array':
            return !value.length;
        case 'Object':
            return isEmptrObject(value);
        default :return false
    }
}
export function isEmptrObject(e){
    var t
    for (t in e){return !1}
    return !0
}
/**************************************************************** */
// 控制指令
/**************************************************************** */
// 获取设备时间
export function cmdControlGetDevice(deviceid){
    return request({
        url: elevatorApi + `/api/CmdTcp/RequestMcTime/${deviceid}`,
        method: 'post',
    });
}
// 校对设备时间
export function cmdControlSetDevice(deviceid){
    return request({
        url: elevatorApi +`/api/CmdTcp/CollateTime/${deviceid}`,
        method: 'post',
    });
}
// 重启设备
export function cmdControlRestartDevice(deviceid){
    return request({
        url: elevatorApi +`/api/CmdTcp/RestartMC/${deviceid}`,
        method: 'post',
    });
}
// 启用或关闭ITM functionType:true | false
export function cmdControlSetITMFunction(elevatorid,functionType){
    return request({
        url: elevatorApi + `/api/CmdTcp/SetMcItmFunc/${elevatorid}/${functionType}`,
        method: 'post',
    });
}
// 远程访客，互访，室内召梯
export function cmdControlRemoteFunction(params){
    return request({
        url: elevatorApi + `/api/CmdTcp/RemoteUnlockVisit`,
        method: 'post',
        data:params,
    });
}
// 远程控制电梯
export function cmdControlElevator(params){
    return request({
        url: elevatorApi + `/api/CmdTcp/MobileCtlEle`,
        method: 'post',
        data:params,
    });
}
/**************************************************************** */
// 查询
/**************************************************************** */
// 获取站点  传入id为站点Id(siteId)
export function getbuildsiteId(id) {
    return request({
        url: elevatorApi + `/api/Building/GetBuildByBvId/${id}`,
        method: 'post',
    });
}

/*【电梯】电梯信息访问接口*/
// 访问接口用于获取电梯列表
export function getApiElevatorList(params) {
    return request({
        url: elevatorApi + "/api/EleProductNum/GetList",
        method: 'post',
        data:params,
    });
}
// 用于获取电梯列表 params = {AreaId:0,ClientId:0}
export function getElevatorList(clientid,areaid){
    const list = []
    const params = {AreaId:areaid,ClientId:clientid}
    let data = getApiElevatorList(params).then((res)=>{
        if(res.code===200){
            return res.data
        }else{
            return []
        }
      }
    )
    if(!isEmptrValue(data)){
        data.forEach(
            item =>{
                const model = {
                    value:item.id,
                    label:item.eleName
                }
                list.push(model)
           }
        )
    }
    return list
}

/*【楼栋/单元】单元楼栋信息访问接口*/
// 访问API接口用于获取楼栋单元列表
export function getApiBuildList(clientid,areaid) {
    const params = {bd_areaID:areaid,bd_clientId:clientid}
    return request({
        url: elevatorApi + "/api/Building/GetList",
        method: 'post',
        data:params,
    });
}
// 用于获取楼栋单元列表 params = {AreaId:0,ClientId:0}
export function getBuildList(clientid,areaid){
    const list = []
    const params = {bd_areaID:areaid,bd_clientId:clientid}
    getApiBuildList(params).then((res)=>{
        if(res.code===200){
            changeBuildModel(res.data)
        }else{
            return []
        }
      }
    )
}

export function getBuildListModel(data){
    for(let i=0;i<data.length;i++){
        const model = {
            value:data[i].bd_ID,
            label:data[i].bd_name
        }
        list.push(model)
    }
    return list
}
/*【楼层】单元楼栋楼层信息访问接口*/
// 访问API接口用于获取楼栋单元列表
export function getApiFloorList(params) {
    return request({
        url: elevatorApi + "/api/Floor/GetList",
        method: 'post',
        data:params,
    });
}
// 访问API接口用于获取楼栋单元列表
export function getApiFloorListByMcId(params) {
    return request({
        url: elevatorApi + "/api/EleFloor/GetList",
        method: 'post',
        data:params,
    });
}
// 用于获取楼栋楼层列表 params = {AreaId:0,ClientId:0}
export function getFloorList(buildid){
    const list = []
    const params = {'fr_ID':buildid}
    let data = getApiFloorList(params).then((res)=>{
        if(res.code===200){
          if(!isEmptrValue(data)){
                data.forEach(
                    item =>{
                        const model = {
                            key:item.id,
                            title:item.fr_show
                        }
                        list.push(model)
                   }
                )
          }
          return list        
        }else{
            return []
        }
      }
    )
}/*【房间】单元楼栋房间信息访问接口*/
// 访问API接口用于获取楼栋单元列表
export function getApiRoomList(params) {
    return request({
        url: elevatorApi + "/api/Room/GetList",
        method: 'post',
        data:params,
    });
}
// 用于获取楼栋房间列表 params = {buildid:楼栋id,disPlayfloor:楼层数显}
export function getRoomList(buildid,disPlayfloor){
    const list = []
    const params = {'rm_bd_ID':buildid,'rm_floor':disPlayfloor}
    let data = getApiRoomList(params).then((res)=>{
        if(res.code===200){
            return res.data
        }else{
            return []
        }
      }
    )
    if(!isEmptrValue(data)){
        data.forEach(
            item =>{
                const model = {
                    value:item.rm_ID,
                    label:item.rm_ShowName
                }
                list.push(model)
           }
        )
    }
    return list
}
/*【读卡器，开锁位置】单元设备信息访问接口*/
// 访问API接口用于获取读卡器，开锁位置列表
export function getApiLadyList(params) {
    return request({
        url: elevatorApi + "/api/EleInfo/GetList",
        method: 'post',
        data:params,
    });
}
// 访问API接口用于获取读卡器，开锁位置列表
export function getApiCardReaderList(params) {
    const data = {
        "pageInfo": params,
         "startDate": "2000-01-15T09:02:44.516Z",
         "endDate": "2099-05-15T09:02:44.516Z",
         "pageNo": 1,
         "pageSize": 1000,
         "sortField": "string",
         "ascSortOrder": true
    }
    return request({
        url: elevatorApi + "/api/EleInfo/GetPage",
        method: 'post',
        data:data,
    });
}
export function getApiCardReaderPage(data) {
    return request({
        url: elevatorApi + "/api/EleInfo/GetPage",
        method: 'post',
        data:data,
    });
} 
/*【设备】单元设备信息访问接口*/
// 访问API接口用于获取电梯列表
export function getApiDeviceList(params) {
    const data = {
        "pageInfo": params,
         "startDate": "2000-01-15T09:02:44.516Z",
         "endDate": "2099-12-30T09:02:44.516Z",
         "pageNo": 1,
         "pageSize": 1000,
         "sortField": "string",
         "ascSortOrder": true
    }
    return request({
        url: elevatorApi + "/api/MCInfo/GetPage",
        method: 'post',
        data:data,
    });
}                     
// 用于获取设备信息列表 params = {AreaId:0,ClientId:0}
export function getDeviceList(clientid,areaid,buildid){
    const list = []
    const params = {'mc_areaID':areaid,'mc_clientID':clientid,'mc_buildingID':buildid}
    let data = getApiDeviceList(params).then((res)=>{
        if(res.code===200){
            return res.data
        }else{
            return []
        }
      }
    )
    if(!isEmptrValue(data)){
        data.forEach(
        item =>{
            const model = {
                id:item.mc_ID,
                deviceName:item.mcName,  //设备名称
                deviceNum:item.mc_num, //编号
                deviceFacility:item.mc_facilityNum, //序列号
                deviceip:item.ip_mcStr,
                deviceport:item.port_mc,
                deviceMASK:item.ip_maskStr,
                deviceGateway:item.ip_gatewayStr,
                deviceStaus:item.connectStatus, //设备状态
                deviceReaderStaus:item.readerConnectOk?1:0,//读卡器状态
                deviceConnectElevatorStaus:item.mcConnectEleStatus?1:0,//设备连接电梯通信状态
                deviceITM:item.itmFunc?1:0, //ITM投入与退出
                version:item.appVersion_master, //固件版本
                versionData:item.appVersion_sub, //固件版本日期
                directTime:[item.direct_time_beginStr,item.direct_time_endStr],//夜间直驶时间
                wechatId:item.mc_wxAreaCloudId,//微信云iD
                lastConnectDeviceIP:item.mc_ReceiveIP,//上次连接设备来源IP地址
                lastConnectDevicDate:item.mc_connect_time,//上次连接设备时间
            }
            list.push(model)
        }
      )
    }
    return list
}
// 访问API接口用于获取电梯分页
function getApiDevicePage(params) {
    return request({
        url: elevatorApi + "/api/MCInfo/GetPage",
        method: 'post',
        data:params,
    });
} 
// 用于获取设备信息列表 params = {AreaId:0,ClientId:0,buildid:0,deviceName:''}
export function getDevicePage(clientid,areaid,buildid,deviceName){
    const list = []
    const params = {
        "pageInfo":{'mc_areaID':areaid,'mc_clientID':clientid,'mc_buildingID':buildid,'mcName':deviceName},
        "startDate": "1990-01-01T00:00:01.855Z",
        "endDate": "2999-01-01T00:00:01.855Z",
        "pageNo": 1,
        "pageSize": 1000,
        "sortField": "string",
        "ascSortOrder": true
    } 
    let data = getApiDevicePage(params).then((res)=>{
        if(res.code===200){
            return res.data.list
        }else{
            return []
        }
      }
    )
    if(!isEmptrValue(data)){
        data.forEach(
        item =>{
            const model = {
                id:item.mc_ID,
                deviceName:item.mcName,  //设备名称
                deviceNum:item.mc_num, //编号
                deviceFacility:item.mc_facilityNum, //序列号
                deviceip:item.ip_mcStr,
                deviceport:item.port_mc,
                deviceMASK:item.ip_maskStr,
                deviceGateway:item.ip_gatewayStr,
                deviceStaus:item.connectStatus, //设备状态
                deviceReaderStaus:item.readerConnectOk?1:0,//读卡器状态
                deviceConnectElevatorStaus:item.mcConnectEleStatus?1:0,//设备连接电梯通信状态
                deviceITM:item.itmFunc?1:0, //ITM投入与退出
                version:item.appVersion_master, //固件版本
                versionData:item.appVersion_sub, //固件版本日期
                directTime:[item.direct_time_beginStr,item.direct_time_endStr],//夜间直驶时间
                wechatId:item.mc_wxAreaCloudId,//微信云iD
                lastConnectDeviceIP:item.mc_ReceiveIP,//上次连接设备来源IP地址
                lastConnectDevicDate:item.mc_connect_time,//上次连接设备时间
            }
            list.push(model)
        }
      )
    }
    return list
}

/*电梯状态信息访问接口*/
// 访问API接口用于获取电梯状态列表
export function getApiElevatorStateList(params) {
    return request({
        url: elevatorApi + "/api/CmdEleStatus/BathEleStatus",
        method: 'post',
        data:params,
    });
}
// 用于获取电梯状态列表 params = {"id": 0,"mcId": 0,"eleInMcNum": 0,"clientId": 0,"areaId": 0}
export function getElevatorStateList(params){
    const list = []
    let data = getApiElevatorStateList(params).then((res)=>{
        if(res.code===200){
            return res.data
        }else{
            return []
        }
      }
    )
    if(!isEmptrValue(data)){
        data.forEach(
            item =>{
                // ACD码
                let acdcode = 0
                switch(item.eleStatusRep.eleStatauInfo2.code){
                    case 0:acdcode = 0;break; // 故障
                    case 3:acdcode = 3;break; // 停止
                    case 4:acdcode = 4;break; // 检修
                    case 19:acdcode = 19;break; // 消防
                    case 36:acdcode = 36;break; // VIP/专用
                    case 39:acdcode = 39;break; // 正常
                    default :acdcode = 0;break; // 故障
                }
                // 运行方向
                let runDirection = 0
                switch(item.eleStatusRep.eleStatauInfo2.runDirection){
                    case 0:runDirection = 0;break; // 静止/无方向
                    case 1:runDirection = 1;break; // 上行
                    case 2:runDirection = 2;break; // 下行
                    case 3:runDirection = 3;break; // 上行(运行)
                    case 4:runDirection = 4;break; // 下行(运行)
                    default :runDirection = 0;break; // 故障
                }
                const model = {'id':item.id,"code":item.code,'name':item.eleStatusRep.eleStatauInfo2.name,"runDirection":runDirection,"floor":item.eleStatusRep.eleStatauInfo2.floor,"doorStatus":item.eleStatusRep.eleStatauInfo2.doorStatus?1:0,"abccode":acdcode,"Itm":item.eleStatusRep.eleStatauInfo2.itm?1:0,"lastdate":moment(item.eleStatusRep.recorddate).format('YYYY-MM-DD HH:mm:ss') }
                list.push(model)
           }
        ) 
    }
    return list
}

/*记录信息访问接口*/
// 访问API接口用于获取梯控记录分页
export function getApiHistoryPage(clientid,params) {
    return request({
        url: elevatorApi + `/api/CardRecord/GetPage/${clientid}`,
        method: 'post',
        data:params,
    });
}
// 访问API接口用于获取微信二维码记录分页
export function getApiQRCodeHistoryPage(clientid,params) {
    return request({
        url: elevatorApi + `/api/Wx_brushTwoBarRecord/GetPage/${clientid}`,
        method: 'post',
        data:params,
    });
}
// 访问API接口用于获取微信二维码记录分页
export function getApiRemoteControlHistoryPage(params) {
    return request({
        url: elevatorApi + `/api/Wx_TcpControl/GetPage`,
        method: 'post',
        data:params,
    });
} 
// 用于获取记录信息列表 params = {AreaId:0,ClientId:0,buildid:0,deviceName:''}
export function getHistoryPage(clientid,areaid,params){
    const list = []
    const newparams = {
        "pageInfo":{'rc_areaId':areaid,'rc_card_ID':params.cardUid,'ps_num':params.userNum,'certifyRslt':params.result},
        "startDate": params.startDate,
        "endDate": params.endDate,
        "pageNo": 1,
        "pageSize": 10,
        "sortField": "string",
        "ascSortOrder": true
    } 
    let data = getHistoryPage(clientid,newparams).then((res)=>{
        if(res.code===200){
            return res.data.list
        }else{
            return []
        }
      }
    )
    if(!isEmptrValue(data)){
        data.forEach(
        item =>{
            const model = {
                id:item.mc_ID,
                deviceName:item.mcName,  //设备名称
                deviceNum:item.mc_num, //编号
                deviceFacility:item.mc_facilityNum, //序列号
                deviceip:item.ip_mcStr,
                deviceport:item.port_mc,
                deviceMASK:item.ip_maskStr,
                deviceGateway:item.ip_gatewayStr,
                deviceStaus:item.connectStatus, //设备状态
                deviceReaderStaus:item.readerConnectOk?1:0,//读卡器状态
                deviceConnectElevatorStaus:item.mcConnectEleStatus?1:0,//设备连接电梯通信状态
                deviceITM:item.itmFunc?1:0, //ITM投入与退出
                version:item.appVersion_master, //固件版本
                versionData:item.appVersion_sub, //固件版本日期
                directTime:[item.direct_time_beginStr,item.direct_time_endStr],//夜间直驶时间
                wechatId:item.mc_wxAreaCloudId,//微信云iD
                lastConnectDeviceIP:item.mc_ReceiveIP,//上次连接设备来源IP地址
                lastConnectDevicDate:item.mc_connect_time,//上次连接设备时间
            }
            list.push(model)
        }
      )
    }
    return list
}


// 电梯模块--实际数据页
// 电梯模块--实际数据页--数据下载
export function dataDownloadZip(body, query) {
    return request({
        url: elevatorApi + `/api/CardRecord/DownBrushCardCsv/${query.siteId}/${body}`,
        method: 'get',
        responseType:'blob'
    });
}
// 电梯模块--实际数据页--数据输出
export function dataDownload(body, query) {
    return request({
        url: elevatorApi + `/api/CardRecord/GetCsvByBivale/${query.siteId}`,
        method: 'post',
        data: body
    });
}
// 电梯模块--实际数据页--卡列表数据查询 
export function getAllUseCardList(body, query) {
    return request({
        url: elevatorApi + `/api/CardRecord/GetCsvByBivale/${query.siteId}`,
        method: 'post',
        data: body,
    });
}

// 电梯模块--实际数据页--卡列表数据查询
export function getAllUseCardPage(body,query) {
    return request({
        url:  elevatorApi + `/api/CardIdInfo/GetPage/${query.siteId}`,
        method: 'post',
        data: body,
    });
}

// 电梯模块--时间段
// 获取时间段列表（需要前端生成key+value）
export function getTimeIntervalList(params) {
    return request({
        url:  elevatorApi + `/api/MCTimeInterval/GetList`,
        method: 'post',
        data: params,
    });
}
// 获取时间段信息
export function getTimeInterval(id) {
    return request({
        url:  elevatorApi + `/api/MCTimeInterval/${id}`,
        method: 'get',
    });
}
// 设置时间段信息
export function setTimeInterval(params) {
    return request({
        url:  elevatorApi + `/api/MCTimeInterval/${params.mti_ID}`,
        method: 'put',
        data: params,
    });
}
// 新增时间段
export function addTimeInterval(params) {
    return request({
        url:  elevatorApi + `/api/MCTimeInterval`,
        method: 'post',
        data: params,
    });
}
// 获取下一个时间段的ID
export function getTimeIntervalNextID(areaid) {
    return request({
        url:  elevatorApi + `/api/MCTimeInterval/GetNextMtiNum/${areaid}`,
        method: 'post',
    });
}

// 更新楼层时间段列表
export function updateTimeIntervalFloor(data) {
    return request({
        url:  elevatorApi + `/api/EleFloor/BatchUpdate`,
        method: 'post',
        data: data,
    });
}
// 设置楼层时间段 /CmdTcp/SetMCFloorLimit/39
export function applicationToDevice(id) {
    return request({
        url:  elevatorApi + `/api/CmdTcp/SetMCFloorLimit/${id}`,
        method: 'post',
    });
}
// 获取时间段关联的控制器
export function getConnectDeviceByTimeInterval(id) {
    return request({
        url:  elevatorApi + `/api/MCInTimeInterval/GetListMcByMtiId/${id}`,
        method: 'post',
    });
}
// 通过时间段关联的控制器
export function putConnectDeviceByTimeInterval(id,arr) {
    return request({
        url:  elevatorApi + `/api/MCInTimeInterval/BathcBindMcByMtiId/${id}`,
        method: 'post',
        data: arr
    });
}

// 解除关联的控制器
export function deleteTimeInterval(id) {
    return request({
        url:  elevatorApi + `/api/MCInTimeInterval/${id}`,
        method: 'delete'
    });
}

// 解除关联的控制器前先查控制器已绑定的时间段
export function setTimeIntervalsBatchToDevices(timeid,fun,arry) {
    return request({
        url:  elevatorApi + `api/CmdTcp/SetMcTimeIntervalBatch/${timeid}/${fun}`,
        method: 'post',
        data: arry
    });
}