<!--门禁模块--报表数据页--全部-->
<template>
  <div id="all">
    <div class="header">
      <div class="header_left">
        <div>
          <a-radio-group v-model="Dateselection" button-style="solid">
            <a-radio-button :value="0">{{$t("elevatorRealdata.a7")}}</a-radio-button>
            <a-radio-button :value="1">{{$t("elevatorRealdata.a8")}}</a-radio-button>
          </a-radio-group>
        </div>
        <div class="header_left_bottom">
          <div class="header_left_bottom_date">
            <span>{{$t("elevatorRealdata.a9"),}}</span>
            <a-date-picker v-model="date" />
            <span class="interval">～</span>
            <span>{{ date1 }}</span>
          </div>
          <div class="header_left_bottom_right">
            <transition name="slide-fade">
              <div class="file" v-if="Dateselection == 0">
                <span>{{this.$t("elevatorRealdata.a10")}}</span>
                <a-select v-model="fileValue" style="width: 70px">
                  <a-select-option v-for="n in 5" :key="n" :value="n">
                    {{ n }}
                  </a-select-option>
                </a-select>
              </div>
            </transition>
            <transition name="slide-fade">
              <div class="date" v-if="Dateselection == 1">
                <span>{{this.$t("elevatorRealdata.a11")}}</span>
                <a-select v-model="day" style="width: 70px">
                  <a-select-option v-for="n in days" :key="n" :value="n">
                    {{ n }}
                  </a-select-option>
                </a-select>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <transition name="slide-fade">
        <div class="" v-if="Dateselection == 0">
          <p class="inquire_explain">
            {{$t("elevatorRealdata.a12")}}<br />
            {{$t("elevatorRealdata.a13")}}<br />
            {{$t("elevatorRealdata.a14")}}<br />
            {{$t("elevatorRealdata.a15")}}<br />
            {{$t("elevatorRealdata.a16")}}<br />
            {{$t("elevatorRealdata.a17")}}
          </p>
        </div>
      </transition>
    </div>
    <div class="main"></div>
    <div class="floor">
      <div class="floor_left">
        <div class="floor_title">
          <span>{{$t("elevatorRealdata.a18")}}</span>
        </div>
        <div class="floor_output" v-if="loading">
          <a-icon type="sync" spin />
          <span>{{$t("elevatorRealdata.a19")}}</span>
        </div>
        <div class="floor_download" v-if="filendownload">
          <span>{{ filename }}</span>
          <a-icon type="download" @click="download_click()" />
        </div>
      </div>
      <div>
        <a-button type="primary" @click="output" style="line-height:40px;height:40px;width:80px;">{{this.$t("elevatorRealdata.a20")}}</a-button>
      </div>
    </div>
  </div>
</template>


<script>
import moment from "moment";
import { dataDownload,dataDownloadZip } from "../../../api/elevator";

export default {
  props: {
    onlyCardHistory: {
      type: Boolean,
    },
   siteid: {
     type: Object,
   },
  },
  data() {
    return {
      loading: false,
      filendownload: false,
      dataType: 0,
      Dateselection: 0,
      date: moment().add(-29, 'days'),
      date1: "",
      date2: "",
      fileValue: 1,
      days: 30,
      day: 1,
      filename: "",
      file: {},
    };
  },
  watch: {
    // 监听月报和日报选择，改变对应结束日期和输出日数
    Dateselection: {
      handler: function (newValue, oldValue) {
        if (newValue == 0) {
          this.date = moment().add(-29, 'days')
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(1, "months")
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        } else if (newValue == 1) {
          this.date = moment()
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(this.day - 1, "day")
            .format("YYYY-MM-DD");
          const year = moment(date).format("YYYY");
          const month = moment(date).format("MM");
          const days = new Date(year, month, 1);
          this.days = new Date(days.getTime() - 864e5).getDate();
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听开始日期选择，改变对应结束日期和输出日数
    date: {
      handler: function (newValue, oldValue) {
        const date = this.Dateselection;
        if (date == 0) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(1, "months")
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        } else if (date == 1) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(this.day - 1, "day")
            .format("YYYY-MM-DD");
          const year = moment(date).format("YYYY");
          const month = moment(date).format("MM");
          const days = new Date(year, month, 1);
          this.days = new Date(days.getTime() - 864e5).getDate();
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听输出日数，改变结束日期
    day: {
      handler: function (newValue, oldValue) {
        if (this.Dateselection == 1) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(this.day - 1, "day")
            .format("YYYY-MM-DD");
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    moment,
    // 文件输出
    output() {
      if (this.date == null) {
        this.$error({
          title: "没有选择日期",
          content: "请选择日期",
          centered: true,
        });
      } else {
        this.loading = true;
        this.filendownload = false;
        const body = {
          // dataType: this.dataType,
          dateStart: this.date.format("YYYY-MM-DD"),
          dateEnd: moment(this.date1, "YYYY-MM-DD").format("YYYY-MM-DD"),
          fileCount: this.fileValue,
          rc_card_ID: null,
          lsCardInfo_Id: null,
          lsEleInfo:null  
        };
        const query = {
          siteId: this.siteid.build,
        };
        dataDownload(body, query)
          .then((res) => {
            this.loading = false;
            this.filendownload = true;
            this.filename = res.data;
            this.file = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    // 文件下载
    download() {
      let url = window.URL.createObjectURL(new Blob([this.file]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", this.filename);
      document.body.appendChild(link);  
      link.click();
      window.URL.revokeObjectURL(link.href)
    },
    download_click() {
       const query = {
        siteId: this.siteid.area,
      }; 
      dataDownloadZip(this.filename,query) .then((res) => {
        this.file = res
        this.download()
       })
      .catch((err) => {
        console.log(err);
      });
      // let url = window.URL.createObjectURL(this.file);
      // let link = document.createElement("a");
      // link.style.display = "none";
      // link.href = url;
      // link.setAttribute("download", this.filename);
      // document.body.appendChild(link);
      // link.click();
    },
  },
};
</script>

<style scoped>
#all {
  height: 100%;
  padding: 10px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-top: none;
  overflow: hidden;
}
.header {
  display: flex;
  height: 128px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.header_left {
  margin-right: 100px;
}
.header_left_bottom {
  display: flex;
  align-items: center;
  height: 95px;
  width: 600px;
  font-size: 14px;
}
.header_left_bottom_date {
  margin-right: 30px;
}
.header_left_bottom_right {
  position: relative;
  width: 185px;
  height: 32px;
}
.main {
  height: calc(100% - 168px);
}
.floor {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.floor_left {
  display: flex;
}
.floor_title {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  padding: 0px 10px;
  background: #7682ce;
}
.floor_download {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 15px;
}
.floor_output {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  font-size: 15px;
  margin-left: 15px;
}
.floor_download >>> .anticon {
  margin-left: 15px;
  font-size: 24px;
}
.floor_download >>> .anticon:hover {
  color: #1f6ce0;
}
.file {
  position: absolute;
}
.interval {
  font-size: 20px;
  margin: 0px 10px;
}
.inquire_explain {
  font-size: 13px;
  margin: 0;
  white-space: nowrap;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>