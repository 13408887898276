<!--门禁模块--报表数据页--卡-->
<template>
  <div id="card">
    <div class="header">
      <div>
        <a-radio-group v-model="Dateselection" button-style="solid">
          <a-radio-button :value="0">{{$t("elevatorRealdata.a7")}}</a-radio-button>
          <a-radio-button :value="1">{{$t("elevatorRealdata.a8")}}</a-radio-button>
        </a-radio-group>
      </div>
      <div class="header_left_bottom">
        <div class="header_left_bottom_date">
          <span>{{$t("elevatorRealdata.a9")}}</span>
          <a-date-picker v-model="date" />
          <span class="interval">～</span>
          <span>{{ date1 }}</span>
        </div>
        <div class="header_left_bottom_right">
          <transition name="slide-fade">
            <div class="file" v-if="Dateselection == 0">
              <span>{{$t("elevatorRealdata.a10")}}</span>
              <a-select v-model="fileValue" style="width: 70px">
                <a-select-option v-for="n in 5" :key="n" :value="n">
                  {{ n }}
                </a-select-option>
              </a-select>
            </div>
          </transition>
          <transition name="slide-fade">
            <div class="date" v-if="Dateselection == 1">
              <span>{{$t("elevatorRealdata.a11")}}</span>
              <a-select v-model="day" style="width: 70px">
                <a-select-option v-for="n in days" :key="n" :value="n">
                  {{ n }}
                </a-select-option>
              </a-select>
            </div>
          </transition>
        </div>
      </div>
      <transition name="slide-fade">
        <div class="" v-if="Dateselection == 0">
          <a-popover placement="bottom">
            <template slot="content">
              <p class="inquire_explain">
                {{$t("elevatorRealdata.a12")}}<br />
                {{$t("elevatorRealdata.a13")}}<br />
                {{$t("elevatorRealdata.a14")}}<br />
                {{$t("elevatorRealdata.a15")}}<br />
                {{$t("elevatorRealdata.a16")}}<br />
                {{$t("elevatorRealdata.a17")}}
              </p>
            </template>
            <a-tag color="blue" style="margin-top:5px;"> {{$t("elevatorRealdata.a21")}} </a-tag>
          </a-popover>
        </div>
      </transition>
    </div>
    <div
      class="main"
      ref="main"
      v-loading="mainLoading"
      :element-loading-text="$t('elevatorRealdata.a1')"
    >
      <div class="main_header">
        <div class="main_header_condition">
          <div>
            <span>{{$t('elevatorRealdata.a22')}}</span>
            <a-input
              v-model="personName"
              allow-clear
              :placeholder="$t('elevatorRealdata.a23')"
              style="width: 160px"
            />
          </div>
          <div>
            <span>{{$t('elevatorRealdata.a24')}}</span>
            <a-input
              v-model="cardFaceNo"
              allow-clear
              :placeholder="$t('elevatorRealdata.a25')"
              style="width: 160px"
            />
          </div>
          <div>
            <span>{{$t('elevatorRealdata.a26')}}</span>
            <a-input
              v-model="cardNo"
              allow-clear
              :placeholder="$t('elevatorRealdata.a27')"
              style="width: 160px"
            />
          </div>
        </div>
        <div>
          <a-button type="primary" @click="inquire">{{$t('elevatorRealdata.a30')}}</a-button>
        </div>
      </div>
      <my-tabletitle>{{$t('elevatorRealdata.a31')}}</my-tabletitle>
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :scroll="size"
        :pagination="pagination"
        :dataSource="tableData"
        :rowKey="(record) => record.card_ID"
      >
      </a-table>
    </div>
    <div class="floor">
      <div class="floor_left">
        <div class="floor_title">
          <span>{{$t("elevatorRealdata.a18")}}</span>
        </div>
        <div class="floor_output" v-if="loading">
          <a-icon type="sync" spin />
          <span>{{$t("elevatorRealdata.a19")}}</span>
        </div>
        <div class="floor_download" v-if="filendownload">
          <span>{{ filename }}</span>
          <a-icon type="download" @click="downloadclick()" />
        </div>
      </div>
      <div>
        <a-button type="primary" @click="output" :disabled="disabled" style="line-height:40px;height:40px;width:80px;"
          >{{$t("elevatorRealdata.a20")}}</a-button
        >
      </div>
    </div>
  </div>
</template>


<script>
import tabletitle from "../../../components/Title/tabletitle";
import moment from "moment";
import { dataDownload, dataDownloadZip,getAllUseCardPage } from "../../../api/elevator";
import {
  tableSort
} from "@/utils/utils";
export default {
  props: {
    onlyCardHistory: {
      type: Boolean,
    },
    siteid: {
      type: Object,
    },
  },
  data() {
    return {
      mainLoading: false,
      loading: false,
      filendownload: false,
      dataType: 0,
      Dateselection: 0,
      date: moment().add(-29, 'days'),
      date1: "",
      date2: "",
      fileValue: 1,
      days: 30,
      day: 1,
      filename: "",
      file: {},
      cardFaceNo: "",
      cardNo: "",
      managementCode: "",
      personName: "",
      tableData: [],
      size: { y: 180 },
      selectedRowKeys: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 10, //每页中显示10条数据
        showTotal: (total) => this.$t("elevatorRealdata.a37") + ` ${total} `+ this.$t("elevatorRealdata.a32"), //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
        },
        {
          title: "卡编号",
          dataIndex: "card_num",
          width: 140,
          sorter: (a, b) => {
            return tableSort(a, b, "card_num");
          },
          ellipsis: true,
        },
        {
          title: "卡ID",
          dataIndex: "card_ID",
          width: 180,
          sorter: (a, b) => {
            return tableSort(a, b, "card_ID");
          },
          ellipsis: true,
        },
        {
          title: "管理代码",
          dataIndex: "ps_num",
          width: 140,
          sorter: (a, b) => {
            return tableSort(a, b, "ps_num");
          },
          ellipsis: true,
        },
        {
          title: "姓名",
          dataIndex: "card_user",
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "card_user");
          },
          ellipsis: true,
        },
        {
          title: "管理所属",
          dataIndex: "buildName",
          width: 180,
          sorter: (a, b) => {
            return tableSort(a, b, "buildName");
          },
          ellipsis: true,
        },
      ],
    };
  },
  watch: {
    // 监听月报和日报选择，改变对应结束日期和输出日数
    Dateselection: {
      handler: function (newValue, oldValue) {
        if (newValue == 0) {
          this.date = moment().add(-29, 'days')
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(1, "months")
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        } else if (newValue == 1) {
          this.date = moment()
          this.fileValue = 1
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(this.day - 1, "day")
            .format("YYYY-MM-DD");
          const year = moment(date).format("YYYY");
          const month = moment(date).format("MM");
          const days = new Date(year, month, 1);
          this.days = new Date(days.getTime() - 864e5).getDate();
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听开始日期选择，改变对应结束日期和输出日数
    date: {
      handler: function (newValue, oldValue) {
        const date = this.Dateselection;
        if (date == 0) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(1, "months")
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        } else if (date == 1) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(this.day - 1, "day")
            .format("YYYY-MM-DD");
          const year = moment(date).format("YYYY");
          const month = moment(date).format("MM");
          const days = new Date(year, month, 1);
          this.days = new Date(days.getTime() - 864e5).getDate();
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听输出日数，改变结束日期
    day: {
      handler: function (newValue, oldValue) {
        if (this.Dateselection == 1) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(this.day - 1, "day")
            .format("YYYY-MM-DD");
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    disabled: function () {
      const length = this.selectedRowKeys.length;
      if (length == 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
    this.inquire()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods: {
    moment,
    // 表格高度计算
    tableSize() {
      let height = this.$refs.main.clientHeight;
      let tableHeight = height - 220;
      this.size.y = tableHeight;
    },
    // 文件输出
    output() {
      if (this.date == null) {
        this.$error({
          title: "没有选择日期",
          content: "请选择日期",
          centered: true,
        });
      } else {
        this.loading = true;
        this.filendownload = false;
        const body1 = {
          dataType: this.dataType,
          endDate: moment(this.date1, "YYYY-MM-DD").format("YYYY-MM-DD"),
          fileCount: this.fileValue,
          ids: this.selectedRowKeys,
          monthlyOrDaily: this.Dateselection,
          onlyCardHistory: this.onlyCardHistory,
          startDate: this.date.format("YYYY-MM-DD"),
        };
        const query1 = {
          siteId: this.siteid.build,
        };
        const body = {
            dateStart: this.date.format("YYYY-MM-DD"),
            dateEnd: moment(this.date1, "YYYY-MM-DD").format("YYYY-MM-DD"),
            fileCount: this.fileValue,
            rc_card_ID: this.selectedRowKeys,
            lsCardInfo_Id: null,
            lsEleInfo:null  
        };
        const query = {
            siteId: this.siteid.build,
        };
        dataDownload(body, query)
          .then((res) => {
            this.loading = false;
            this.filendownload = true;
            this.filename = res.data;
            // this.file = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    // 文件下载
    download(res) {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", this.filename);
      document.body.appendChild(link);  
      link.click();
      window.URL.revokeObjectURL(link.href)
    },
    downloadclick() {
       const query = {
        siteId: this.siteid.area,
      }; 
      dataDownloadZip(this.filename,query) .then((res) => {
        this.file = res
        this.download(res)
       })
      .catch((err) => {
        console.log(err);
      });
    },
    // 卡列表查询
    inquire() {
      this.mainLoading = true;
      const body = {
        "pageInfo": {
          "card_user": this.personName,
          "card_ID": this.cardNo,
          "card_num": this.cardFaceNo
        },
        "startDate": "1990-01-01 00:00:00",
        "endDate": "2099-12-30 23:59:59",
        "pageNo": this.pagination.defaultCurrent,
        "pageSize":this.pagination.pageSize,
        "sortField": "string",
        "ascSortOrder": true
       };
       const query = {
        siteId: this.siteid.client,
      };
      getAllUseCardPage(body,query) .then((res) => {
          this.mainLoading = false;
          const { data } = res.data.list;
          this.pagination.defaultCurrent = res.data.pageNo
          this.pagination.total = res.data.total
          // this.pagination.current = res.data.pageNo
          this.tableData = res.data.list;
      })
    },
    // 卡勾选的方法
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
#card {
  height: 100%;
  padding: 10px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-top: none;
  overflow: hidden;
}
.header {
  display: flex;
  height: 50px;
  min-width: 900px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.header_left_bottom {
  display: flex;
  width: 650px;
  font-size: 14px;
}
.header_left_bottom_date {
  margin: 0px 30px;
}
.header_left_bottom_right {
  position: relative;
  width: 185px;
  height: 32px;
}
.main {
  height: calc(100% - 90px);
  overflow: hidden;
}
.main_header {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  padding: 10px 0px;
}
.main_header_condition {
  display: flex;
  font-size: 14px;
  color: rgba(12, 12, 12, 0.7);
}
.main_header_condition span {
  margin-right: 15px;
}
.floor {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.floor_left {
  display: flex;
}
.floor_title {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  padding: 0px 10px;
  background: #7682ce;
}
.floor_download {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 15px;
}
.floor_output {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  font-size: 15px;
  margin-left: 15px;
}
.floor_download >>> .anticon {
  margin-left: 15px;
  font-size: 24px;
}
.floor_download >>> .anticon:hover {
  color: #1f6ce0;
}
.loading {
  width: 100px;
}
.file {
  position: absolute;
}
.interval {
  font-size: 20px;
  margin: 0px 10px;
}
.inquire_explain {
  font-size: 13px;
  margin: 0;
  white-space: nowrap;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>