 <!--门禁模块--报表数据页-->
 <template>
  <div id="realdata">
    <div class="header">
      <my-headertitle>
          {{this.$t("elevatorRealdata.a2")}}    
        <template v-slot:name >
          <a-radio-group v-model="Pageselection" button-style="solid" style="margin-left:100px;" >
            <a-radio-button value="全部">{{$t("elevatorRealdata.a3")}}</a-radio-button>
            <a-radio-button value="卡">{{$t("elevatorRealdata.a4")}}</a-radio-button>
            <a-radio-button value="读卡器">{{$t("elevatorRealdata.a5")}}</a-radio-button>
          </a-radio-group>
        </template>
      </my-headertitle>
    </div>
    <transition name="slide-fade">
      <div class="main" v-if="Pageselection == '全部'">
        <my-all :siteid="id"></my-all>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="main" v-if="Pageselection == '卡'">
        <my-card :siteid="id"></my-card>
      </div>
    </transition>
     <transition name="slide-fade">
   <div class="main" v-if="Pageselection == '读卡器'">
     <my-door :siteid="id"></my-door>
   </div>
 </transition>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import realdataAll from "./components/realdataAll";
import realdataDoor from "./components/realdataDoor";
import realdataCard from "./components/realdataCard";
import { getbuildsiteId } from "../../api/elevator";
export default {
  name:"reportData",
  data() {
    return {
      Pageselection:'全部',
      id:{
        client:0,
        area:0,
        build:0
      }
    };
  },
  components: {
    "my-headertitle": headerTitle,
    "my-all": realdataAll,
    "my-door": realdataDoor,
    "my-card": realdataCard,
  },
  async created() {
    await getbuildsiteId(this.$route.query.id).then((res) =>{
      this.id.client = res.data.bd_clientId
      this.id.area = res.data.bd_areaID
      this.id.build = res.data.bd_ID
    }).catch((err) => {
        console.log("访问站点转换出错", err);
    });
  },
};
</script>

<style scoped>
#realdata {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* overflow-y: auto; */
  position: relative;
}
.header {
  padding: 0px 20px;
}
.cut {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.main {
  width: 100%;
  height: calc(100% - 63px);
  padding: 0px 20px 10px 20px;
  position: absolute;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>